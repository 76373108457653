<template>
  <div class="cart-page page">
    <BreadcrumbsComponent @back.prevent title="Корзина" />
    <div class="container-padding">
      <div class="cart-page__inner page__inner">
        <div v-if="cartItems && cartItems.length" class="cart-page__content">
          <div class="cart-page__body">
            <div class="cart-page__header">
              <span class="cart-page__title">Состав заказа</span>
              <button @click="clearCartModal" class="cart-page__clear btn">Очистить корзину</button>
            </div>
            <div class="cart-page__products">
              <ProductCardCartVersionComponent v-for="(item, index) in cartItems" :key="index" :data="item" />
            </div>
            <div class="cart-page__block">
              <div class="cart-page__sub-block">
                <h3 class="cart-page__block-title">Доставка</h3>
                <div class="cart-page__methods">
                  <label
                    v-for="(item, i) in deliveries"
                    :key="i"
                    class="cart-page__method"
                    :class="{ 'cart-page__method--active': isChecked(item, 'delivery_type_id') }"
                  >
                    <RadioComponent
                      :checked="isChecked(item, 'delivery_type_id')"
                      @change="changeRadio($event, item, 'delivery_type_id')"
                    >
                      <div class="cart-page__method-info">
                        <span class="cart-page__method-title">{{ item.title }}</span>
                        <span class="cart-page__method-subtitle">{{ item.subtitle }}</span>
                      </div>
                    </RadioComponent>
                  </label>
                </div>
              </div>
              <div class="cart-page__sub-block">
                <h3 class="cart-page__block-title">Комментарий к заказу</h3>
                <TextareaComponent
                  v-model="deliveryForm.commentary.value"
                  :error="deliveryForm.commentary.error"
                  placeholder="Введите ваш комментарий здесь"
                  :required="false"
                />
              </div>
              <div
                v-if="deliveryForm.delivery_type_id.value?.type === 2"
                ref="recipientForm"
                class="cart-page__sub-block"
              >
                <h3 class="cart-page__block-title">Адрес доставки</h3>
                <a class="cart-page__address-select" href="#" @click="showAddressesModal">
                  <InputComponent
                    :value="currentAddress || 'Укажите адрес'"
                    :error="recipientForm.address.error"
                    disabled
                  />
                </a>
              </div>
              <div
                v-if="deliveryForm.delivery_type_id.value?.type === 1"
                ref="recipientForm"
                class="cart-page__sub-block"
              >
                <h3 class="cart-page__block-title">Адрес магазина</h3>
                <a class="cart-page__address-select">
                  <InputComponent :value="address" disabled />
                </a>
              </div>
            </div>
            <div class="cart-page__block">
              <h3 class="cart-page__block-title">Оплата</h3>
              <div class="cart-page__methods">
                <RadioComponent
                  v-for="(item, i) in payments"
                  :key="i"
                  :checked="isChecked(item, 'payment_type_id')"
                  @change="changeRadio($event, item, 'payment_type_id')"
                  :hideState="true"
                >
                  <div
                    class="cart-page__method"
                    :class="{ 'cart-page__method--active': isChecked(item, 'payment_type_id') }"
                  >
                    <IconComponent class="cart-page__method-icon" :name="item.icon" />
                    <div class="cart-page__method-info">
                      <span class="cart-page__method-title">{{ item.title }}</span>
                      <span class="cart-page__method-subtitle">{{ item.subtitle }}</span>
                    </div>
                  </div>
                </RadioComponent>
              </div>
            </div>
          </div>
          <div class="cart-page__aside">
            <transition name="bounce">
              <DeliveryStatusBarComponent
                v-show="deliveryForm.delivery_type_id.value?.type === 2"
                :min-price="freeDeliveryPrice"
                :total-price="orderSum"
              />
            </transition>
            <CartPageTotalComponent
              :loading="loading"
              :delivery="deliveryForm.delivery_type_id.value"
              :sum="orderSum"
              :discount="discount"
              :delivery-price="deliveryPrice"
              :cart-items-count="cartItems.length"
              :pay-method="deliveryForm.payment_type_id.value"
              :address="currentAddress"
              @inputAddress="showAddressesModal"
            />
            <PromocodeComponent
              @setPromocode="setPromocode"
              @removePromocode="removePromocode"
              hide-benefits
              gray-promo
            />
            <div class="cart-page__aside-bottom">
              <button @click="orderCreate" class="btn btn--lg btn--main">
                {{ isRegistered ? "Заказать" : "Войти и заказать" }}
              </button>
              <span class="cart-page__aside-terms">
                Нажимая на кнопку, вы соглашаетесь с
                <router-link :to="{ name: 'document', params: { id: types.TERMS } }">
                  условиями использования
                </router-link>
              </span>
            </div>
          </div>
        </div>
        <span v-else class="empty-block">Корзина пуста</span>
        <ProductsSliderComponent title="С этим часто берут" :items="often_buy" />
      </div>
    </div>
  </div>
</template>

<script>
import RadioComponent from "components/inputs/RadioComponent.vue";
import ORDER_CREATE from "@/graphql/mutations/OrderCreate.graphql";
import LoginModal from "components/modals/components/LoginModal.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ProductCardCartVersionComponent from "components/product/ProductCardCartVersionComponent.vue";
import IconComponent from "components/IconComponent.vue";
import ProductsSliderComponent from "components/slider/ProductsSliderComponent.vue";
import CartPageTotalComponent from "./components/TotalComponent.vue";
import PromocodeComponent from "components/PromocodeComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import AddressesModal from "components/modals/components/AddressesModal.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import { removeCartItems } from "@/utils";
import DeliveryStatusBarComponent from "./components/DeliveryStatusBarComponent.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";

export default {
  name: "CartPage",
  async asyncData({ apollo, store }) {
    await store.dispatch("GET_CART_ITEMS", {
      apollo: apollo.defaultClient,
    });
  },
  data() {
    return {
      loading: false,
      debounceTimeout: null,
      subscription: true,
      payments: [
        {
          title: "Наличными",
          subtitle: "Оплата курьеру или в магазине",
          icon: "card",
          type: this.$store.state._types.OFFLINE_CASH_PAY,
        },
        {
          title: "Картой онлайн",
          subtitle: "Оплата картой онлайн МИР, VISA, MasterCard",
          icon: "empty-wallet",
          type: this.$store.state._types.ONLINE_CARD_PAY,
        },
      ],
      shops: [
        {
          id: 1,
          title: "ул. Восстания, дом 1, магазин 1",
        },
        {
          id: 2,
          title: "ул. Восстания, дом 1, магазин 1",
        },
      ],
      deliveries: [
        {
          title: "Доставка по городу",
          subtitle: "Наша служба доставки привезёт заказ домой, в офис или на дачу",
          type: this.$store.state._types.COURIER_DELIVERY,
        },
        {
          title: "Самовывоз",
          subtitle: "Заберите свой заказ бесплатно из магазина после подтверждения",
          type: this.$store.state._types.PICKUP,
        },
      ],
      deliveryForm: {
        // price: {
        //   value: undefined,
        //   error: undefined,
        //   message: undefined,
        //   required: false,
        // },
        shop_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        address: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        payment_type_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        delivery_type_id: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        location: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        city: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        index: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        street: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        house: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        flat: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        entrance: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        code: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        floor: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        commentary: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
        promocode: {
          value: undefined,
          error: undefined,
          message: undefined,
          required: false,
        },
      },
      recipientForm: {
        name: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        surname: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        patronymic: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        phone: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        email: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
        address: {
          value: undefined,
          error: undefined,
          message: undefined,
        },
      },
    };
  },
  watch: {
    "$store.state.auth.user"() {
      this.setUserData();
    },
    "$route.name"() {
      this.removePromocode();
    },
    "$route.params"() {
      this.removePromocode();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.recipientForm.address.value = this.currentAddress;
      if (this.deliveries && this.deliveries.length) {
        this.deliveryForm.delivery_type_id.value = this.deliveries[0];
        this.deliveryForm.shop_id.value = this.shops[0];
        this.deliveryForm.payment_type_id.value = this.payments[0];
      }
      this.removePromocode();
    });
    if (this.isRegistered) {
      this.setUserData();
    }
  },
  computed: {
    types() {
      return this.$store.state._types;
    },
    // товары которые часто берут
    // 10 рандомных товаров из связок каждого товара в корзине
    often_buy() {
      let list = [];
      this.cartItems.forEach((c) => {
        if (c.item && c.item.often_buy && c.item.often_buy.length) {
          list.push(...c.item.often_buy);
        }
      });
      return this.getRandomList(list, 10);
    },
    cartItems() {
      return this.$store.state.auth.cart || [];
    },
    isRegistered() {
      return !!this.$store.state.apollo_token || this.user;
    },
    // deliveries() {
    //   return this.$store.state.auth.deliveries;
    // },
    user() {
      return this.$store.state.auth.user;
    },
    freeDeliveryPrice() {
      return this.$store.state.global.feedback.free_delivery_price;
    },
    address() {
      return this.$store.state.global.feedback.address;
    },
    feedback() {
      return this.$store.state.global.feedback;
    },
    orderSum() {
      return this.cartItems
        .map((r) => this.$options.filters.share_price(r.item?.price, r.item) * r.count)
        .reduce((a, b) => a + b, 0);
    },
    deliveryPrice() {
      return this.orderSum < this.feedback.free_delivery_price ? this.feedback.delivery_price : 0;
    },
    discount() {
      return (
        this.cartItems.map((r) => parseFloat(r.item?.price) * r.count).reduce((a, b) => a + b, 0) -
        this.orderSum
      );
    },
    currentAddress() {
      let addresses = this.$store.state.auth.addresses;
      return addresses && addresses.length ? addresses.find((item) => item.is_active)?.address : null;
      // return addresses && addresses.length ? addresses[addresses.length - 1]?.address : null;
    },
  },
  methods: {
    removeCartItems,
    getRandomList(list, n) {
      let randomList = [];
      for (let i = 0; i < n && i < list.length; i++) {
        let r = Math.floor(Math.random() * (list.length - i)) + i;
        let city = list[r];
        list[r] = list[i];
        list[i] = city;
        randomList.push(city);
      }
      return randomList;
    },
    // showAddressModal() {
    //   this.$store.state._modals.push({
    //     component: this.isRegistered ? AddressModal : LoginModal,
    //   });
    // },
    showAddressesModal() {
      this.$store.state._modals.push({
        component: this.isRegistered ? AddressesModal : LoginModal,
      });
    },
    showAlertModal(options, callback) {
      this.$store.state._modals.push({
        component: AlertModal,
        options: options,
        callback,
      });
    },
    /**
     * Очищение корзины
     */
    clearCartModal() {
      this.showAlertModal({
        title: "Удалить всё из корзины?",
        message: "Просто уточняем, а то вдруг вы случайно задели кнопку",
        isConfirm: true,
        closeBtnTitle: "Оставить",
        confirmBtnTitle: "Удалить",
        closeBtnClass: "btn--gray",
        confirmBtnClass: "btn--red",
        callback: this.clearCart,
      });
    },
    clearCart() {
      let ids = this.cartItems.map((i) => i.id || i.item.id);
      this.removeCartItems({ store: this.$store, apollo: this.$apollo.provider.defaultClient, ids });
    },
    /**
     * Вывод скидки по промокоду
     */
    setPromocode(share, promocode) {
      this.deliveryForm.promocode.value = promocode;
      let promoProducts = share.products.map((p) => p.id);
      this.$store.state.auth.cart.forEach((c) => {
        if (promoProducts.includes(c.item?.id)) {
          c.item.share = {
            id: share.id,
            percent: share.percent,
            type: {
              id: share.type.id,
              code: share.type.code,
              name: share.type.name,
            },
          };
        }
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();
    },
    /**
     * Удаление промокода
     */
    removePromocode() {
      this.deliveryForm.promocode.value = null;
      this.$store.state.auth.cart.forEach((c) => {
        if (c.item.share && c.item.share.type.code === 2) {
          c.item.share = null;
          // delete c.item.share;
        }
      });
      // костыль, изменяем массив, чтобы вызвался watch в App.vue
      // и сохранил изменения в localStorage
      this.$store.state.auth.cart.push({});
      this.$store.state.auth.cart.pop();
    },
    /**
     * Подставить личные данные
     */
    setUserData() {
      if (this.user) {
        this.recipientForm.name.value = this.user.name;
        this.recipientForm.surname.value = this.user.surname;
        this.recipientForm.email.value = this.user.email;
        this.recipientForm.phone.value = this.user.phone;
      }
    },
    /**
     * Оплата
     */
    orderCreate() {
      if (this.isRegistered) {
        if (!this.loading) {
          this.loading = true;
          this.resetErrors("deliveryForm");
          this.resetErrors("recipientForm");
          let errorsCount = 0;
          Object.keys(this.deliveryForm).forEach((key) => {
            if (this.deliveryForm[key].required && !this.deliveryForm[key].value) {
              this.deliveryForm[key].error = "Заполните поле";
              errorsCount += 1;
            }
          });
          if (!errorsCount) {
            this.$apollo
              .mutate({
                mutation: ORDER_CREATE,
                variables: {
                  name: this.recipientForm.name.value,
                  surname: this.recipientForm.surname.value,
                  phone: this.recipientForm.phone.value
                    ? this.recipientForm.phone.value.replace(/[^\d+]/g, "")
                    : undefined,
                  email: this.recipientForm.email.value,
                  commentary: this.deliveryForm.commentary.value,
                  promocode: this.deliveryForm.promocode.value,
                  address:
                    this.deliveryForm.delivery_type_id.value.type === 2 ? this.currentAddress : this.address,
                  delivery_type_id: this.deliveryForm.delivery_type_id.value
                    ? this.deliveryForm.delivery_type_id.value.type
                    : null,
                  payment_type_id: this.deliveryForm.payment_type_id.value
                    ? this.deliveryForm.payment_type_id.value.type
                    : null,
                },
                context: {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.apollo_token,
                  },
                },
              })
              .then(({ data }) => {
                this.loading = false;
                // проверка, выбрал ли пользователь оплату онлайн
                // лучше переделать на проверку на наличие ссылки,
                // т.к. способ поменяется если он выберет другой после клика "оформить заказ"

                if (data && data.OrderCreate) {
                  if (data.OrderCreate.payment_url) {
                    let link = document.createElement("a");
                    link.href = data.OrderCreate.payment_url;
                    link.click();
                    this.clearCart();
                  } else {
                    this.showAlertModal(
                      {
                        title: "Заказ успешно оформлен",
                        message: "Вы можете следить за состоянием заказа в личном кабинете",
                        closeBtnClass: "btn--gray",
                        isConfirm: true,
                        confirmBtnTitle: "Перейти",
                        callback: () => {
                          this.$router.push({ name: "user", params: { link: "orders" } });
                        },
                      },
                      () => {
                        this.clearCart();
                        window.scrollTo(0, 0);
                      }
                    );
                  }
                }
              })
              .catch(({ graphQLErrors }) => {
                this.loading = false;
                this.parseGqlErrors(graphQLErrors);
                this.scrollTo("recipientForm");
              });
          } else {
            this.loading = false;
          }
        }
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
          options: {
            routeAfterLogin: {
              name: this.$router.currentRoute.name,
              params: this.$router.currentRoute.params,
              hash: this.$router.currentRoute.hash,
            },
          },
        });
      }
    },
    /**
     * Изменение способа доставки
     * @param e
     * @param item
     * @param field
     */
    changeRadio(e, item, field) {
      if (e) {
        this.deliveryForm[field].value = item;
      }
    },
    isChecked(item, field) {
      return JSON.stringify(this.deliveryForm[field].value) === JSON.stringify(item);
    },
    /**
     * Скролл до нужного элемента
     * @param refName
     */
    scrollTo(refName) {
      window.scrollTo({ top: this.$refs[refName].offsetTop, behavior: "smooth" });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          let validationKeys = Object.keys(err.extensions.validation);
          if (validationKeys.includes("name") || validationKeys.includes("surname")) {
            this.showAlertModal({
              title: "Ошибка",
              message: "Для оформления заказа необходимо ввести имя и фамилию в личном кабинете",
              isConfirm: true,
              closeBtnTitle: "Отмена",
              confirmBtnTitle: "Изменить",
              closeBtnClass: "btn--gray",
              // confirmBtnClass: "btn--red",
              callback: () => {
                this.$router.push({ name: "user", params: { link: "settings" } });
              },
            });
          }
          // if (validationKeys.includes("address")) {
          // }
          validationKeys.forEach((key) => {
            if (this.recipientForm[key]) {
              this.recipientForm[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
  },
  metaInfo: {
    title: "Корзина",
  },
  components: {
    DeliveryStatusBarComponent,
    InputComponent,
    PromocodeComponent,
    CartPageTotalComponent,
    ProductsSliderComponent,
    IconComponent,
    ProductCardCartVersionComponent,
    BreadcrumbsComponent,
    RadioComponent,
    TextareaComponent,
  },
};
</script>

<style lang="stylus">
.cart-page {
  width 100%
  display grid
  grid-gap 50px
  +below(680px) {
    padding 30px 0
    grid-gap 0
  }

  .breadcrumbs {
    margin 0
  }

  &__inner {
    gap: 50px
  }

  &__content {
    display grid
    grid-gap var(--gap)
    grid-template-columns minmax(600px, 1fr) minmax(350px, 413px)
    align-items start
    +below(1100px) {
      display flex
      flex-direction column
      align-items stretch
      grid-gap 30px
    }
  }

  &__body {
    display flex
    flex-direction column
    gap: var(--gap)
  }

  &__header {
    display flex
    flex-wrap wrap-reverse
    align-items center
    justify-content space-between
    gap: 5px 10px
  }

  &__title {
    font-weight: 700;
    font-size: 1.375em
    line-height: 26px;
    +below(480px) {
      font-size: 1.125em
    }
  }

  &__clear {
    font-size 1em
    line-height: 26px;
    color: var(--dark-light)

    &:hover {
      color var(--red)
    }
  }

  &__products {
    position relative
    z-index 1
    border-radius var(--big-radius)
    padding 5px 15px
    border 1px solid var(--gray-dark)
  }

  &__block {
    display flex
    flex-direction column
    gap: 30px
    padding 30px
    border-radius var(--big-radius)
    border 1px solid var(--gray-dark)

    &-title {
      margin 0
      text-transform none
      font-weight: 700;
      font-size: 1.375em
      line-height: 26px;
    }
  }

  &__sub-block {
    display flex
    flex-direction column
    gap: 30px

    .input {
      width 100%
      cursor pointer
    }
  }

  &__methods {
    display grid
    grid-template-columns repeat(auto-fit, minmax(265px, 1fr))
    grid-gap 20px
  }

  &__method {
    cursor: pointer;
    border: 1px solid var(--gray-dark);
    border-radius: var(--big-radius)
    padding 10px 15px
    display flex
    align-items center
    gap: 15px
    width 100%
    transition var(--transition)

    &-icon {
      width 24px
      height 24px

      svg path {
        fill var(--dark)
        transition var(--transition)
      }
    }

    &--active,
    &:hover {
      color var(--main)
      border-color var(--main)

      .cart-page__method-icon svg path {
        fill var(--main)
      }
    }

    &-info {
      display flex
      flex-direction column
      gap: 2px
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    &-subtitle {
      font-size: 0.875em
      line-height: 20px;
      color: var(--gray-dark-2)
    }
  }

  &__address-select {
    &:hover {
      .input__container {
        border-color var(--main)
      }
    }

    .input {
      pointer-events none
    }
  }

  &__aside {
    display flex
    flex-direction column
    gap: var(--gap)
    max-width 500px
    +above(1101px) {
      position sticky
      //top calc(var(--header-main-height) + 24px)
      top 30px
    }
    +below(1100px) {
      margin 0 auto
    }
    +below(820px) {
      margin 0
      max-width none
    }

    &-bottom {
      display flex
      flex-direction column
      gap: 15px

      .btn {
        height 70px
        font-size: 22px;
        line-height: 26px;
      }
    }

    &-terms {
      font-size: 0.875em
      line-height: 20px;
      color: var(--dark-light);

      a:hover {
        border-bottom 1px solid
      }
    }
  }
}
</style>
