<template>
  <div v-if="data && data.item" class="product-cart">
    <router-link
      :to="{
        name: 'product',
        params: {
          id: item.id,
          link: item.link,
        },
      }"
      class="product-cart__content"
    >
      <ImgComponent class="product-cart__img" :img="item | item_img" width="100" height="100" />
      <div class="product-cart__info">
        <span class="product-card__title">
          {{ item.title }}
        </span>
        <div class="product-card__prices">
          <span class="product-card__price">
            {{ item.price | share_price(item) | formatPrice }}
          </span>
          <span v-if="item.share || item.promo_share" class="product-card__old-price">
            {{ item.price | formatPrice }}
          </span>
        </div>
      </div>
    </router-link>
    <div class="product-cart__actions">
      <ProductCountChangeComponent ref="countChange" :data="item" is-cart />
      <button @click="remove" class="product-cart__remove btn btn--md btn--gray-hollow">
        <IconComponent name="trash" />
      </button>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";

export default {
  name: "ProductCardCartVersionComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    item() {
      return this.data.item || {};
    },
    inFavorites() {
      return this.$store.state.auth.favorites_ids.includes(this.item.id);
    },
  },
  methods: {
    remove() {
      this.$refs.countChange.remove();
    },
  },
  components: { ProductCountChangeComponent, IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/product-card.styl"
.product-cart {
  width 100%
  padding 10px 0
  display flex
  align-items center
  gap: 15px 30px
  +below(768px) {
    flex-direction column
    align-items flex-start
  }

  &:not(:last-child) {
    border-bottom 1px solid var(--gray-dark)
  }

  &__content {
    flex-grow 1
    display flex
    gap 15px
    align-items flex-start
    border-radius: var(--main-radius)
  }

  &__img {
    width 60px
    height 60px
    object-fit contain
    border: 1px solid var(--gray-dark);
    border-radius var(--main-radius)
    background-color var(--white)
    flex-shrink 0
  }

  &__info {
    display flex
    flex-direction column
    gap: 5px
    max-width 350px
  }

  &__actions {
    display flex
    align-items center
    gap: 30px
    +below(768px) {
      width 100%
    }
  }

  &__remove.btn {
    width 50px
    height 50px
    border: 1px solid var(--gray-dark)
    +below(768px) {
      margin-left auto
    }

    &:hover {
      border-color var(--red)

      .icon svg path {
        fill var(--red)
      }
    }

    .icon {
      width: 20px
      height: 20px

      svg path {
        fill var(--dark-light)
        transition var(--transition)
      }
    }
  }
}
</style>
