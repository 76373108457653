var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.item)?_c('div',{staticClass:"product-cart"},[_c('router-link',{staticClass:"product-cart__content",attrs:{"to":{
      name: 'product',
      params: {
        id: _vm.item.id,
        link: _vm.item.link,
      },
    }}},[_c('ImgComponent',{staticClass:"product-cart__img",attrs:{"img":_vm._f("item_img")(_vm.item),"width":"100","height":"100"}}),_c('div',{staticClass:"product-cart__info"},[_c('span',{staticClass:"product-card__title"},[_vm._v("\n        "+_vm._s(_vm.item.title)+"\n      ")]),_c('div',{staticClass:"product-card__prices"},[_c('span',{staticClass:"product-card__price"},[_vm._v("\n          "+_vm._s(_vm._f("formatPrice")(_vm._f("share_price")(_vm.item.price,_vm.item)))+"\n        ")]),(_vm.item.share || _vm.item.promo_share)?_c('span',{staticClass:"product-card__old-price"},[_vm._v("\n          "+_vm._s(_vm._f("formatPrice")(_vm.item.price))+"\n        ")]):_vm._e()])])],1),_c('div',{staticClass:"product-cart__actions"},[_c('ProductCountChangeComponent',{ref:"countChange",attrs:{"data":_vm.item,"is-cart":""}}),_c('button',{staticClass:"product-cart__remove btn btn--md btn--gray-hollow",on:{"click":_vm.remove}},[_c('IconComponent',{attrs:{"name":"trash"}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }