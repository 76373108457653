<template>
  <div class="cart-page-total">
    <div class="cart-page-total__total">
      <span>Итого</span>
      <span>{{ totalSum | formatPrice }}</span>
    </div>
    <ul class="cart-page-total__info">
      <li v-if="cartItemsCount">
        <span>Товары {{ cartItemsCount }} шт.</span>
        <span v-if="discount">{{ (sum + discount) | formatPrice }}</span>
        <span v-else>{{ sum | formatPrice }}</span>
      </li>
      <li v-if="discount" class="cart-page-total__discount">
        <span>Скидка</span>
        <span>{{ discount | formatPrice }}</span>
      </li>
      <li v-if="delivery && delivery.type !== $store.state._types.PICKUP">
        <span>Доставка</span>
        <span>{{ deliveryPrice || 0 | formatPrice }}</span>
      </li>
      <li v-if="delivery?.type === 2">
        <span>Адрес доставки</span>
        <a
          @click.prevent="$emit('inputAddress')"
          class="cart-page-total__address"
          :class="{ 'cart-page-total__address--empty': !address }"
          href="#"
        >
          {{ address || "Выбрать" }}
        </a>
      </li>
      <li>
        <span>Время</span>
        <span>30 мин.</span>
      </li>
      <li v-if="payMethod">
        <span>Оплата</span>
        <span>{{ payMethod.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CartPageTotalComponent",
  props: {
    loading: Boolean,
    sum: Number,
    discount: Number,
    deliveryPrice: Number,
    promocode_discount: Number,
    price_delivery: Number,
    cartItemsCount: Number,
    payMethod: Object,
    delivery: Object,
    address: String,
  },
  computed: {
    totalSum() {
      return this.delivery?.type === 2 ? this.sum + this.deliveryPrice : this.sum;
    },
  },
};
</script>

<style lang="stylus">
.cart-page-total {
  background: var(--gray);
  border-radius: var(--big-radius);
  display grid
  align-items start
  overflow hidden
  border: 1px solid var(--gray-dark);

  &__total {
    display flex
    align-items center
    justify-content space-between
    border-bottom 1px solid var(--gray-dark)
    width 100%
    padding 20px

    span {
      font-weight: 700;
      font-size: 2em
      line-height: 38px;
      display: flex;
      align-items: center;
    }
  }

  &__info {
    display grid
    width 100%
    padding 20px 0

    li {
      display flex
      flex-wrap wrap
      gap: 5px 20px
      width 100%
      justify-content space-between
      padding 10px 30px

      &.cart-page-total__discount {
        span:last-child {
          color var(--red)
        }
      }

      span {
        display: flex;
        align-items: center;
        color: var(--black);

        &:first-child {
          font-weight: 700;
        }
      }
    }
  }

  &__address {
    &--empty {
      text-decoration underline
    }
  }
}
</style>
