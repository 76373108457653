<template>
  <div v-if="minPrice" class="price-status-bar">
    <div class="price-status-bar__bg" :style="{ width: `${percent}%` }"></div>
    <IconComponent name="information-fill" />
    <span>Минимальная сумма заказа для бесплатной доставки: {{ minPrice }} ₽</span>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "DeliveryStatusBarComponent",
  props: {
    minPrice: [Number, String],
    totalPrice: [Number, String],
  },
  computed: {
    percent() {
      return (this.totalPrice / this.minPrice) * 100;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.price-status-bar {
  margin: 2px -2px -2px -2px;
  width 100%
  padding: 16px
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
  display flex
  gap: 8px
  align-items center
  justify-content center
  border-radius 16px
  position relative
  background var(--white)

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: linear-gradient(45deg, #5AB033 0%, #DDE954 100%);
    border-radius: 18px;
    z-index: -1;
  }

  &__bg {
    background: linear-gradient(45deg, #5AB033 0%, #DDE954 100%);
    max-width 100%
    position absolute
    left 0
    height 100%
    transition var(--transition)
    border-radius: 16px;
  }

  & span {
    font-weight: 400;
    font-size: 0.75rem
    line-height: 140%;
    color: #244614
    z-index 1
  }

  & .icon {
    z-index 1
    svg {
      width 20px
      height 20px
    }
  }
}
</style>
